import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "/warehouse",
  name: "Warehouse",
  meta: { sectionAccess: "WAREHOUSE", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "warehouses",
      name: "Warehouses Layout",
      meta: { permissions: [PermissionEnum.WAREHOUSE_WAREHOUSES] },
      redirect: { name: "Warehouses" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "",
          name: "Warehouses",
          component: () => import("@/views/auth/warehouse/WarehouseList.vue"),
        },
        {
          path: "detail/:id",
          name: "Warehouses Detail Layout",
          redirect: { name: "Warehouse Detail" },
          meta: { permissions: [PermissionEnum.WAREHOUSE_WAREHOUSES] },
          component: () => import("@/layouts/EmptyLayout.vue"),
          props: true,
          children: [
            {
              path: "",
              name: "Warehouse Detail",
              redirect: { name: "Stock Tab" },
              component: () => import("@/views/auth/warehouse/WarehouseDetail.vue"),
              props: true,
              children: [
                {
                  path: "stock",
                  name: "Stock Tab",
                  component: () => import("@/modules/warehouse/tabs/WarehouseStockTab.vue"),
                  props: true,
                },
                {
                  path: "item",
                  name: "Item Tab",
                  component: () => import("@/modules/warehouse/tabs/WarehouseItemTab.vue"),
                  props: true,
                },
              ],
            },
            {
              path: "consumable/:consumableId",
              name: "Warehouse Consumable",
              component: () => import("@/views/auth/warehouse/WarehouseConsumableDetail.vue"),
              props: true,
            },
            {
              path: "locations/:type?/:locationId?",
              name: "Locations",
              meta: { permissions: [PermissionEnum.WAREHOUSE_WAREHOUSES] },
              component: () => import("@/views/auth/warehouse/Locations.vue"),
              props: true,
            },
          ],
        },
        {
          path: "new",
          name: "Warehouse New",
          component: () => import("@/views/auth/warehouse/WarehouseNew.vue"),
        },
        {
          path: "edit/:id",
          name: "Warehouse Edit",
          component: () => import("@/views/auth/warehouse/WarehouseEdit.vue"),
          props: true,
        },
      ],
    },
    {
      path: "consumables",
      name: "Consumable Layout",
      meta: { permissions: [PermissionEnum.WAREHOUSE_CONSUMABLES] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "",
          name: "Consumables",
          component: () => import("@/views/auth/warehouse/ConsumableList.vue"),
        },
        {
          path: "detail/:id?",
          name: "Consumable Detail",
          component: () => import("@/views/auth/warehouse/ConsumableDetail.vue"),
          props: true,
        },
        {
          path: "new",
          name: "Consumable New",
          component: () => import("@/views/auth/warehouse/ConsumableNew.vue"),
        },
        {
          path: "edit/:id",
          name: "Consumable Edit",
          component: () => import("@/views/auth/warehouse/ConsumableEdit.vue"),
          props: true,
        },
      ],
    },
    {
      path: "items",
      name: "Items",
      redirect: { name: "Item List" },
      meta: { permissions: [PermissionEnum.WAREHOUSE_ITEMS] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      props: true,
      children: [
        {
          path: "list",
          name: "Item List",
          component: () => import("@/views/auth/warehouse/items/ItemList.vue"),
        },
        {
          path: "new",
          name: "Item New",
          component: () => import("@/views/auth/warehouse/items/ItemNew.vue"),
        },
        {
          path: "edit/:id",
          name: "Item Edit",
          component: () => import("@/views/auth/warehouse/items/ItemEdit.vue"),
          props: true,
        },
        {
          path: "detail/:itemId",
          name: "Item Detail",
          redirect: { name: "Item Amortization" },
          component: () => import("@/views/auth/warehouse/items/ItemDetail.vue"),
          props: true,
          children: [
            {
              path: "amortization",
              name: "Item Amortization",
              component: () => import("@/views/auth/warehouse/items/ItemAmortizationTab.vue"),
              props: true,
            },
            {
              path: "files",
              name: "Item Files",
              component: () => import("@/views/auth/warehouse/items/ItemFilesTab.vue"),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "stock-records",
      name: "Stock Records",
      redirect: { name: "Stock Record List" },
      meta: { permissions: [PermissionEnum.WAREHOUSE_STOCK_RECORDS] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "Stock Record List",
          component: () => import("@/views/auth/warehouse/stock-records/StockRecords.vue"),
        },
        {
          path: "add",
          name: "Add Stock Record",
          component: () => import("@/views/auth/warehouse/stock-records/AddStockRecordFormPage.vue"),
        },
        {
          path: "deduct",
          name: "Deduct Stock Record",
          component: () => import("@/views/auth/warehouse/stock-records/DeductStockRecordFormPage.vue"),
        },
        {
          path: "detail/:id",
          name: "Stock Record Detail",
          redirect: { name: "Stock Record Detail Sidebar" },
          component: () => import("@/views/auth/warehouse/stock-records/StockRecordDetail.vue"),
          children: [
            {
              path: "",
              name: "Stock Record Detail Sidebar",
              components: {
                sidebar: () => import("@/views/auth/warehouse/stock-records/MoveStockSidebar.vue"),
              },
              props: true,
            },
          ],
          props: true,
        },
      ],
    },
    {
      path: "configuration",
      name: "Warehouses Configuration",
      redirect: { name: "Metrics" },
      meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "metrics/:type?/:id?",
          name: "Metrics",
          meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
          component: () => import("@/views/auth/configuration/Metrics.vue"),
          props: true,
        },
        {
          path: "item-models/:type?/:id?",
          name: "ItemModels",
          meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
          component: () => import("@/views/auth/configuration/ItemModels.vue"),
          props: true,
        },
        {
          path: "categories/:type?/:id?",
          name: "Categories",
          meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
          component: () => import("@/views/auth/configuration/Categories.vue"),
          props: true,
        },
        {
          path: "subcategories/:type?/:id?",
          name: "Subcategories",
          meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
          component: () => import("@/views/auth/configuration/Subcategories.vue"),
          props: true,
        },
        {
          path: "brands/:type?/:id?",
          name: "Brands",
          meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIGURATION] },
          component: () => import("@/views/auth/configuration/Brands.vue"),
          props: true,
        },
      ],
    },
    {
      path: "suppliers/:type?/:id?",
      name: "Warehouse Suppliers",
      meta: { permissions: [PermissionEnum.WAREHOUSE_CONFIG_SUPPLIERS] },
      component: () => import("@/views/auth/warehouse/WarehouseSuppliers.vue"),
      props: true,
    },
  ],
} as RouteRecordRaw;
