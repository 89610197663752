import { IColumn } from "@/interfaces/IColumn";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { PermissionEnum } from "@altertec_gparn/lib/";

export const getTaskFileColumns = (me?: UserDto): IColumn<TaskFileDto>[] => [
  {
    label: "Archivo",
    name: "file.mimetype",
    type: "thumbnail",
    field: (taskFile: TaskFileDto) => taskFile.file,
    sortable: true,
    align: "left",
    required: true,
    emitCellClickEvent: true,
    tooltipText: (taskFile: TaskFileDto) => taskFile.file.authorshipText(),
  },
  {
    label: "Nombre",
    name: "file.filename",
    field: (taskFile: TaskFileDto) => taskFile.file?.filename ?? "-",
    sortable: true,
    align: "left",
    required: true,
  },
  {
    label: "Activo",
    name: "asset.code",
    field: (taskFile: TaskFileDto) => (taskFile.task.asset ? taskFile.task.asset : null),
    sortable: true,
    align: "left",
    required: true,
  },
  {
    label: "Parte",
    name: "worksheet.code",
    field: (taskFile: TaskFileDto) => taskFile.task.worksheet?.toString() ?? "-",
    sortable: false,
    align: "left",
    required: true,
    routeFn: (taskFile: TaskFileDto) => {
      if (me?.role?.permissions.includes(PermissionEnum.PROJECTS_WORKSHEET)) {
        return { name: "Worksheet Detail", params: { id: taskFile.task.worksheet.id } };
      }
    },
  },

  {
    label: "Visibilidad",
    name: "isPublic",
    field: "isPublic",
    sortable: false,
    type: "boolean",
    align: "left",
    required: true,
  },
  {
    label: "Fecha de subida",
    type: "date",
    name: "taskFile.updated_at",
    field: (taskFile: TaskFileDto) => taskFile.file?.updatedAt,
    sortable: false,
    align: "left",
    required: true,
  },
];
