import { RouteRecordRaw } from "vue-router";

export default {
  path: "/my-space",
  name: "My space",
  redirect: { name: "My Projects" },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "projects",
      name: "My Projects",
      redirect: { name: "My Projects List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "detail/:id?",
          name: "My Project",
          redirect: { name: "My Project Worksheets" },
          component: () => import("@/views/auth/my-space/MyProject.vue"),
          props: true,
          children: [
            {
              path: "contacts",
              name: "My Project Contacts",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectContactsTab.vue"),
                sidebar: () => import("@/modules/projects/components/ProjectBasicSidebar.vue"),
              },
              props: true,
            },
            {
              path: "documentation",
              name: "My Project Documentation",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectDocumentationTab.vue"),
                sidebar: () => import("@/modules/projects/components/ProjectBasicSidebar.vue"),
              },
              props: true,
            },
            {
              path: "resources",
              name: "My Project Resources",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectResourcesTab.vue"),
                sidebar: () => import("@/modules/projects/components/ProjectBasicSidebar.vue"),
              },
              props: true,
            },
            {
              path: "my-worksheets",
              name: "My Project Worksheets",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectWorksheetsTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/MyProjectWorksheetsSidebar.vue"),
              },
              props: true,
            },
            {
              path: "my-expenses",
              name: "My Project Expenses",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectExpensesTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/MyProjectExpensesSidebar.vue"),
              },
              props: true,
            },
            {
              path: "my-on-calls",
              name: "My Project On Calls",
              components: {
                default: () => import("@/modules/projects/components/tabs/MyProjectOnCallsTab.vue"),
                sidebar: () => import("@/modules/projects/components/sidebars/MyProjectOnCallsSidebar.vue"),
              },
              props: true,
            },
          ],
        },
        {
          path: "list",
          name: "My Projects List",
          component: () => import("@/views/auth/my-space/MyProjectList.vue"),
          children: [
            {
              path: ":id/add-expense",
              name: "My Projects - Add Expense",
              components: {
                sidebar: () => import("@/modules/projects/components/sidebars/AddExpense.vue"),
              },
              props: true,
            },
            {
              path: ":id/add-worksheet",
              name: "My Projects - Add",
              components: {
                sidebar: () => import("@/modules/projects/components/sidebars/AddWorksheet.vue"),
              },
              props: true,
            },
            {
              path: ":id/add-on-call",
              name: "My Projects - Add on call",
              components: {
                sidebar: () => import("@/modules/on-calls/components/sidebars/MyOnCallNew.vue"),
              },
              props: true,
            },
          ],
        },
        {
          path: "worksheet",
          name: "My Worksheets",
          redirect: { name: "My Worksheet List" },
          component: () => import("@/layouts/EmptyLayout.vue"),
          children: [
            {
              path: "list",
              name: "My Worksheet List",
              component: () => import("@/views/auth/my-space/MyWorksheetList.vue"),
            },
            {
              path: "detail/:id?",
              name: "My Worksheet",
              redirect: { name: "My Worksheet Sidebar" },
              component: () => import("@/views/auth/my-space/MyWorksheetDetail.vue"),
              props: true,
              children: [
                {
                  name: "My Worksheet Sidebar",
                  path: "",
                  components: {
                    sidebar: () =>
                      import("@/modules/worksheets/components/sidebars/my-worksheets/MyWorksheetRightSidebar.vue"),
                  },
                  props: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "expenses",
      name: "My Expenses",
      component: () => import("@/views/auth/my-space/MyExpenses.vue"),
      children: [
        {
          path: ":id/edit-expense",
          name: "My Expenses - Edit",
          components: {
            sidebar: () => import("@/modules/expenses/components/sidebars/MyExpenseEdit.vue"),
          },
          props: true,
        },
      ],
    },
    {
      path: "times-off",
      name: "My Times Off",
      redirect: { name: "My Times Off List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "My Times Off List",
          component: () => import("@/views/auth/my-space/MyTimeOffList.vue"),
          children: [
            {
              path: "new",
              name: "New Time Off",
              components: {
                sidebar: () => import("@/modules/times-off/components/sidebars/MyTimeOffNew.vue"),
              },
            },
          ],
        },
        {
          path: "detail/:id",
          name: "My Time Off Detail",
          redirect: { name: "My Time Off Sidebar" },
          components: {
            default: () => import("@/views/auth/my-space/MyTimeOffDetail.vue"),
          },
          props: true,
          children: [
            {
              path: "",
              name: "My Time Off Sidebar",
              components: {
                sidebar: () => import("@/modules/times-off/view-parts/MyTimeOffRightSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "on-call",
      name: "My On Call",
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "detail/:id",
          name: "My On Call Detail",
          redirect: { name: "My On Call Sidebar" },
          components: {
            default: () => import("@/views/auth/my-space/MyOnCallDetail.vue"),
          },
          props: true,
          children: [
            {
              path: "",
              name: "My On Call Sidebar",
              components: {
                sidebar: () => import("@/modules/on-calls/components/sidebars/OnCallSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "calendar/:paramDate?",
      name: "My Calendar",
      component: () => import("@/views/auth/my-space/MyCalendar.vue"),
      props: true,
    },
    {
      path: "posts",
      name: "My Post View",
      redirect: { name: "My Post List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "My Post List",
          component: () => import("@/views/auth/my-space/MyPostList.vue"),
        },
        {
          path: ":id",
          name: "My Post Detail",
          props: true,
          component: () => import("@/views/auth/my-space/MyPostDetail.vue"),
        },
      ],
    },
  ],
} as RouteRecordRaw;
