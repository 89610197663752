import { Transform } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";

export class CostSummaryDto {
  @Transform(({ value }) => toEuros(value))
  totalCost = 0;

  @Transform(({ value }) => toEuros(value))
  usersCost = 0;

  @Transform(({ value }) => toEuros(value))
  vehiclesCost = 0;

  @Transform(({ value }) => toEuros(value))
  dietsCost = 0;

  @Transform(({ value }) => toEuros(value))
  expensesCost = 0;
}
