import { Expose } from "class-transformer";

export class CreateAnswerOptionDto {
  @Expose()
  answer: string;

  @Expose()
  generatesIncident: boolean;

  @Expose()
  questionId: string;
}
