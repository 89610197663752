import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { QuestionDto } from "@/core/features/questions/question.dto";

export class AnswerOptionDto extends BaseEntityDto {
  answer: string;

  generatesIncident: boolean;

  @Type(() => QuestionDto)
  question?: QuestionDto;
  questionId: string;

  toString(): string {
    return `${this.answer}`;
  }
}
