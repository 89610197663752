import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { UpdateItemDto } from "@/core/features/items/update-item.dto";
import { CreateItemDto } from "@/core/features/items/create-item.dto";
import { ItemDto } from "@/core/features/items/item.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { UpdateItemArchivedStatusDto } from "@/core/features/items/update-item-archived-status.dto";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { InvoiceDto } from "@/core/features/invoice/invoice.dto";
import { DeleteFileDto } from "@/core/features/file/delete-file.dto";

export class ItemsResource extends ResourceBaseService<ItemDto, CreateItemDto, UpdateItemDto> {
  protected EntityDto = ItemDto;
  protected CreateEntityDto = CreateItemDto;
  protected UpdateEntityDto = UpdateItemDto;

  constructor(protected readonly apiHttClient: ApiHttpClient) {
    super(apiHttClient, "/items");
  }

  async updateArchiveStatus(id: string, body: UpdateItemArchivedStatusDto): Promise<ItemDto> {
    return this.apiHttpClient.request<UpdateItemArchivedStatusDto, ItemDto>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/archive",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateItemArchivedStatusDto, { ...body, id }, { excludeExtraneousValues: true }),
      })
    );
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<InvoiceDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<InvoiceDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }
}
