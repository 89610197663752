import { Expose, Transform } from "class-transformer";
import { OnCallStatusEnum } from "@altertec_gparn/lib";
import { parseAppDate } from "@/utils/dates/dateFormats";

export class UpdateOnCallDto {
  @Expose()
  status?: OnCallStatusEnum;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  start: Date;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  end: Date;

  @Expose()
  onCallTypeId: string;

  @Expose()
  description?: string;

  @Expose()
  workOrderClient?: string;
}
