export enum QuestionDropdownType {
  PERSONALIZED = "PERSONALIZED",
  EQUIPMENT = "EQUIPMENT",
  DOCUMENTATION = "DOCUMENTATION",
}

export const getQuestionDropdownTypeLabel = (dropdownType: QuestionDropdownType): string => {
  switch (dropdownType) {
    case QuestionDropdownType.PERSONALIZED:
      return "Personalizado";
    case QuestionDropdownType.EQUIPMENT:
      return "Equipamiento";
    case QuestionDropdownType.DOCUMENTATION:
      return "Documentación";
  }
};

export const getQuestionDropdownTypeOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(QuestionDropdownType).filter(
    (key: string) => typeof QuestionDropdownType[key as keyof typeof QuestionDropdownType] === "string"
  );
  return keys.map((val) => {
    const value = QuestionDropdownType[val as keyof typeof QuestionDropdownType];
    return { label: getQuestionDropdownTypeLabel(value), value: value };
  });
};
