import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "hr",
  name: "HR",
  meta: { sectionAccess: "HR", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "times-off",
      name: "Times Off",
      redirect: { name: "Times Off List" },
      meta: { permissions: [PermissionEnum.HR_TIMES_OFF] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "new",
          name: "Create Time Off",
          component: () => import("@/views/auth/hr/time-off/TimeOffCreate.vue"),
        },
        {
          path: "list",
          name: "Times Off List",
          component: () => import("@/views/auth/hr/time-off/TimeOffList.vue"),
        },
        {
          path: "detail/:id",
          name: "Time Off Detail",
          redirect: { name: "Time Off Sidebar" },
          component: () => import("@/views/auth/hr/time-off/TimeOffDetailLayout.vue"),
          props: true,
          children: [
            {
              path: "",
              name: "Time Off Sidebar",
              components: { sidebar: () => import("@/modules/times-off/components/sidebars/TimeOffSidebar.vue") },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "on-calls",
      name: "On Calls",
      meta: { permissions: [PermissionEnum.HR_ON_CALL] },
      redirect: { name: "On Calls List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "new",
          name: "Create On Call",
          component: () => import("@/views/auth/hr/on-call/OnCallCreate.vue"),
        },
        {
          path: "list",
          name: "On Calls List",
          component: () => import("@/views/auth/hr/on-call/OnCallList.vue"),
        },
        {
          path: "detail/:id",
          name: "On Call Detail",
          redirect: { name: "On Call Sidebar" },
          component: () => import("@/views/auth/hr/on-call/OnCallDetailLayout.vue"),
          props: true,
          children: [
            {
              path: "",
              name: "On Call Sidebar",
              components: { sidebar: () => import("@/modules/on-calls/components/sidebars/OnCallSidebar.vue") },
              props: true,
            },
          ],
        },
      ],
    },

    {
      path: "employees",
      name: "Employees View",
      redirect: { name: "Employee List" },
      meta: { permissions: [PermissionEnum.HR_EMPLOYEES] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "detail/:id",
          name: "Employee Detail",
          redirect: { name: "Employee Data" },
          component: () => import("@/views/auth/hr/employee/EmployeeDetail.vue"),
          props: true,
          children: [
            {
              path: "data",
              name: "Employee Data",
              component: () => import("@/modules/hr/components/tabs/EmployeeDataTab.vue"),
              props: true,
            },
            {
              path: "qualifications",
              name: "Employee's Qualifications",
              components: {
                default: () => import("@/modules/hr/components/tabs/EmployeeQualificationsTab.vue"),
                sidebar: () => import("@/modules/hr/components/sidebars/EmployeeHasQualificationSidebar.vue"),
              },
              props: true,
            },
            {
              path: "vacations",
              name: "Employee's Vacations",
              components: {
                default: () => import("@/modules/hr/components/tabs/EmployeeVacationsTab.vue"),
                sidebar: () => import("@/modules/hr/components/sidebars/AdjustVacationDaysSidebar.vue"),
              },
              props: true,
            },
          ],
        },
        {
          path: "list",
          name: "Employee List",
          component: () => import("@/views/auth/hr/employee/Employees.vue"),
        },
        {
          path: "edit/:id",
          name: "Edit Employee",
          props: true,
          component: () => import("@/views/auth/hr/employee/EditEmployee.vue"),
        },
      ],
    },
    {
      path: "posts",
      name: "Post View",
      redirect: { name: "Post List" },
      meta: { permissions: [PermissionEnum.HR_POSTS] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "list",
          name: "Post List",
          component: () => import("@/views/auth/hr/posts/Posts.vue"),
        },

        {
          path: "create",
          name: "Create Post",
          component: () => import("@/views/auth/hr/posts/CreatePost.vue"),
        },
        {
          path: ":id",
          name: "Detail Post",
          props: true,
          component: () => import("@/views/auth/hr/posts/PostDetail.vue"),
        },
      ],
    },
    {
      path: "configuration",
      name: "HR Configuration",
      redirect: { name: "Collective Agreements" },
      meta: { permissions: [PermissionEnum.HR_CONFIGURATION] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "collective-agreements/:type?/:id?",
          name: "Collective Agreements",
          component: () => import("@/views/auth/configuration/collective-agreements/CollectiveAgreements.vue"),
          props: true,
        },
        {
          path: "time-off-types/:type?/:id?",
          name: "Time Off Types",
          component: () => import("@/views/auth/configuration/TimeOffTypes.vue"),
          props: true,
        },
        {
          path: "on-call-types/:type?/:id?",
          name: "On Call Types",
          component: () => import("@/views/auth/configuration/OnCallTypes.vue"),
          props: true,
        },
        {
          path: "qualifications/:type?/:id?",
          name: "Qualifications",
          component: () => import("@/views/auth/configuration/Qualifications.vue"),
          props: true,
        },
        {
          path: "qualifications-report",
          name: "Qualifications Report",
          component: () => import("@/views/auth/configuration/QualificationsReport.vue"),
          props: true,
        },
      ],
    },
  ],
} as RouteRecordRaw;
