import { getArchivedFilter } from "@/utils/filterDefinitions";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { getWorksheetStatusOptions } from "@altertec_gparn/lib";
import { getCustomOptionLoader } from "@/utils/tableUtils";
import { booleanOptions } from "@/utils/booleanOptions";
import { ICustomOptionLoader } from "@/interfaces/ICustomOptionLoader";
import { OptionType } from "@/types/OptionType";
import { ONLY_NOT_NULL, ONLY_NULL } from "@/constants/Filters";
import { get } from "lodash";

export function getWorksheetFilters(
  component: ComponentPublicInstance,
  customOptionLoaders: ICustomOptionLoader[],
  dateRangeAlwaysVisible: boolean
): IFilterDefinition[] {
  return [
    {
      label: "Expediente",
      value: "project.dossierId",
      type: "select",
      selectMultiple: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived?: boolean) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "project.dossierId");
        return filter
          ? filter.optionLoader(inputValue, includeArchived)
          : component.$dossiersPresenter.optionLoader(inputValue, includeArchived);
      },
    },
    {
      value: "project.dossier.clientGroupId",
      label: "Grupo de clientes",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$clientGroupsResource.optionLoader(inputValue),
    },
    {
      value: "projectId",
      label: "Proyecto",
      type: "select",
      selectMultiple: true,
      useInput: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived?: boolean) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "projectId");
        return filter
          ? filter.optionLoader(inputValue, includeArchived)
          : component.$projectsResource.optionLoader(inputValue, includeArchived);
      },
    },
    {
      value: "project.offer.code",
      label: "Oferta",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "project.offer.code");
        return filter
          ? filter.optionLoader(inputValue)
          : component.$offersResource.optionLoaderDistinctCode(inputValue);
      },
    },
    {
      value: "dateRange",
      label: "Rango de fechas",
      type: "date-range",
      alwaysVisible: dateRangeAlwaysVisible,
    },
    {
      label: "Centro",
      value: "project.workplaceId",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "project.workplaceId");
        return filter ? filter.optionLoader(inputValue) : component.$workplacesResource.optionLoader(inputValue);
      },
    },
    {
      label: "Unidad Operativa",
      value: "project.operativeUnitId",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "project.operativeUnitId");
        return filter
          ? filter.optionLoader(inputValue)
          : component.$operativeUnitsResource.optionLoaderMine(inputValue);
      },
    },
    {
      label: "Grupo de clientes",
      value: "project.clientGroupId",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => component.$clientGroupsResource.optionLoader(inputValue),
    },
    {
      value: "authorId",
      label: "Autor",
      type: "select",
      useInput: true,
      selectMultiple: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived: boolean) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "authorId");
        return filter
          ? filter.optionLoader(inputValue, includeArchived)
          : component.$usersResource.optionLoader(inputValue, includeArchived);
      },
      hasAssignMe: true,
    },
    {
      value: "worksheetHasUsers.isTeamLeader",
      label: "Tiene jefe de equipo",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "worksheetHasUsers.userId",
      label: "Técnicos",
      type: "select",
      selectMultiple: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived?: boolean) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "worksheetHasUsers.userId");
        return filter
          ? filter.optionLoader(inputValue, includeArchived)
          : component.$usersResource.optionLoader(inputValue, includeArchived);
      },
      hasAssignMe: true,
    },
    {
      value: "worksheetHasVehicles.vehicleId",
      label: "Vehículos",
      type: "select",
      selectMultiple: true,
      hasArchived: true,
      optionLoader: async (inputValue: string, includeArchived?: boolean) =>
        component.$vehiclesResource.optionLoader(inputValue, includeArchived),
    },
    {
      value: "worksheetHasVehicles.isOwnVehicle",
      label: "¿Tiene vehículo propio?",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "certificationId",
      label: "Certificación",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string) => {
        const filter = getCustomOptionLoader(customOptionLoaders, "certificationId");
        return filter ? filter.optionLoader(inputValue) : component.$certificationsResource.optionLoader(inputValue);
      },
    },
    {
      value: "certification.id",
      label: "¿Tiene certificación?",
      type: "select",
      optionLoader: async (): Promise<OptionType[]> => [
        { value: ONLY_NULL, label: "NO" },
        { value: ONLY_NOT_NULL, label: "SI" },
      ],
    },
    {
      value: "isNightWork",
      label: "Nocturno",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "hasLunchBreak",
      label: "Se para a comer",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "isAbroad",
      label: "Trabajo realizado en el extranjero",
      type: "select",
      optionLoader: async () => booleanOptions,
    },
    {
      value: "status",
      label: "Estado",
      type: "select",
      selectMultiple: true,
      optionLoader: async () => getWorksheetStatusOptions(),
    },
    {
      value: "tasks.taskTypeId",
      label: "Tipo de tarea",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue) => component.$taskTypesResource.optionLoader(inputValue),
    },
    {
      value: "tasks.subtaskId",
      label: "Subtarea",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue) => component.$subtasksResource.optionLoader(inputValue, { limit: 100 }),
    },
    {
      value: "tasks.assetId",
      label: "Activo",
      type: "select",
      selectMultiple: true,
      optionLoader: async (inputValue: string, includeArchived: boolean, model) => {
        const workplaceId = get(model, "project.workplaceId");
        const request = workplaceId ? { filters: { workplaceId: workplaceId } } : null;
        return component.$assetsResource.optionLoader(inputValue, request);
      },
    },
    getArchivedFilter(),
  ];
}
