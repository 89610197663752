import { Transform, Type } from "class-transformer";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";
import { ConsumableDto } from "@/core/features/consumables/consumable.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { toEuros } from "@/utils/numberUtils";

export class WarehouseConsumableDto extends BaseEntityDto {
  @Type(() => WarehouseDto)
  warehouse: WarehouseDto;
  warehouseId: string;

  @Type(() => ConsumableDto)
  consumable: ConsumableDto;
  consumableId: string;

  @Transform(({ value }) => Number(value))
  stock: number;

  isStockAboveMinimum: boolean;

  @Transform(({ value }) => toEuros(value))
  averagePrice: number;
}
