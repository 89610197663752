import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "/scheduler",
  name: "Scheduler Layout",
  meta: { sectionAccess: "SCHEDULER", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "",
      name: "Scheduler Calendar Layout",
      redirect: { name: "Schedule Resources" },
      meta: { permissions: [PermissionEnum.SCHEDULER_CALENDAR] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "resources",
          name: "Schedule Resources",
          components: { default: () => import("@/views/auth/scheduler/ScheduleResources.vue") },
        },
        {
          path: "calendar",
          name: "Scheduler",
          components: { default: () => import("@/views/auth/scheduler/Scheduler.vue") },
          children: [
            {
              path: "idle-technicians/:paramDate",
              name: "Idle Technicians - Scheduler",
              components: {
                sidebar: () => import("@/modules/scheduler/components/sidebars/IdleTechniciansSidebar.vue"),
              },
              props: true,
            },
          ],
        },
        {
          path: "project/:id?",
          name: "Scheduler Project Detail",
          redirect: { name: "Scheduler Project Resources" },
          component: () => import("@/views/auth/scheduler/SchedulerProjectDetail.vue"),
          props: true,
          children: [
            {
              path: "resources",
              name: "Scheduler Project Resources",
              components: {
                default: () => import("@/modules/scheduler/components/tabs/SchedulerProjectResourcesTab.vue"),
                sidebar: () => import("@/modules/scheduler/view-parts/project-detail/SchedulerProjectSidebar.vue"),
              },
              props: true,
            },
            {
              path: "contacts",
              name: "Scheduler Project Contacts",
              components: {
                default: () => import("@/modules/scheduler/components/tabs/SchedulerProjectContactsTab.vue"),
                sidebar: () => import("@/modules/scheduler/view-parts/project-detail/SchedulerProjectSidebar.vue"),
              },
              props: true,
            },
            {
              path: "required-qualification",
              name: "Scheduler Project Required Qualification",
              components: {
                default: () =>
                  import("@/modules/scheduler/components/tabs/SchedulerProjectRequiredQualificationTab.vue"),
                sidebar: () => import("@/modules/scheduler/view-parts/project-detail/SchedulerProjectSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "/orp",
      name: "ORP",
      redirect: { name: "ORP'S Project" },
      meta: { permissions: [PermissionEnum.SCHEDULER_ORP] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "projects",
          name: "ORP'S Project",
          redirect: { name: "ORP'S Project List" },
          component: () => import("@/layouts/EmptyLayout.vue"),
          children: [
            {
              path: "list",
              name: "ORP'S Project List",
              component: () => import("@/views/auth/scheduler/ORPProjectList.vue"),
            },
            {
              path: "detail/:id",
              name: "ORP'S Project Detail",
              redirect: { name: "ORP Project Resources" },
              component: () => import("@/views/auth/scheduler/OrpProjectDetail.vue"),
              props: true,
              children: [
                {
                  path: "resources",
                  name: "ORP Project Resources",
                  components: {
                    default: () => import("@/modules/scheduler/components/tabs/OrpProjectResourcesTab.vue"),
                    sidebar: () => import("@/modules/scheduler/view-parts/orp-project-detail/OrpProjectSidebar.vue"),
                  },
                  props: true,
                },
                {
                  path: "contacts",
                  name: "ORP Project Contacts",
                  components: {
                    default: () => import("@/modules/scheduler/components/tabs/OrpProjectContactsTab.vue"),
                    sidebar: () => import("@/modules/scheduler/view-parts/orp-project-detail/OrpProjectSidebar.vue"),
                  },
                  props: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "configuration",
      name: "ORP Configuration",
      redirect: { name: "Documentation Issue Types" },
      meta: { permissions: [PermissionEnum.SCHEDULER_ORP_CONFIGURATION] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "documentation-issue-types/:type?/:id?",
          name: "Documentation Issue Types",
          component: () => import("@/views/auth/configuration/DocumentationIssueTypes.vue"),
          props: true,
        },
      ],
    },
  ],
} as RouteRecordRaw;
