export enum ReviewPeriodEnum {
  THREE_MONTHS = "THREE_MONTHS",
  ONE_MONTH = "ONE_MONTH",
  WHEN_USED = "WHEN_USED",
}

export const getReviewPeriodLabel = (reviewPeriod: ReviewPeriodEnum): string => {
  switch (reviewPeriod) {
    case ReviewPeriodEnum.THREE_MONTHS:
      return "3 meses";
    case ReviewPeriodEnum.ONE_MONTH:
      return "1 mes";
    case ReviewPeriodEnum.WHEN_USED:
      return "Cuando se usa el vehículo";
  }
};

export const getReviewPeriodOptions = (): { label: string; value: string }[] => {
  const keys = Object.keys(ReviewPeriodEnum).filter(
    (key: string) => typeof ReviewPeriodEnum[key as keyof typeof ReviewPeriodEnum] === "string"
  );
  return keys.map((val) => {
    const value = ReviewPeriodEnum[val as keyof typeof ReviewPeriodEnum];
    return { label: getReviewPeriodLabel(value), value: value };
  });
};
