import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { UserDto } from "./user.dto";
import { CreateUserDto } from "./create-user.dto";
import { UpdateUserDto } from "./update-user.dto";
import { IRequest } from "@/interfaces/IRequest";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { CondOperator } from "@nestjsx/crud-request";
import { plainToInstance } from "class-transformer";
import { HttpRequest } from "@/core/common/http/http-request";
import { CacheService } from "@/core/common/storage/cache.service";
import { OptionType } from "@/types/OptionType";
import { PermissionEnum } from "@altertec_gparn/lib";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { UpdateUserAccessDto } from "@/core/features/users/update-user-access.dto";
import { cloneDeep, merge } from "lodash";
import { QualificationDto } from "@/core/features/qualifications/qualification.dto";

export class UsersResource extends ResourceBaseService<UserDto, CreateUserDto, UpdateUserDto> {
  protected EntityDto = UserDto;
  protected CreateEntityDto = CreateUserDto;
  protected UpdateEntityDto = UpdateUserDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient, protected readonly cacheService: CacheService) {
    super(apiHttpClient, "/users");
  }

  async getMe(forceRefresh?: boolean): Promise<UserDto> {
    let myUser = this.cacheService.get("myUser") as UserDto;
    if (myUser && !forceRefresh) return plainToInstance(this.EntityDto, myUser);
    myUser = await this.getMeByApi();
    this.cacheService.save("myUser", myUser);
    return plainToInstance(this.EntityDto, myUser);
  }

  private async getMeByApi(): Promise<UserDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/me",
        method: "GET",
        auth: true,
      })
    );
  }

  public async getIdleUsers(date: string, request: IRequest): Promise<FindManyResult<UserDto>> {
    return this.apiHttpClient
      .request<CreateUserDto, FindManyResult<UserDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/idle/" + date,
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(UserDto, findManyResult.data);
        return findManyResult;
      });
  }

  public async optionLoaderByRole(
    role: PermissionEnum,
    inputValue: string,
    includeArchived?: boolean
  ): Promise<OptionType[]> {
    const request: IRequest = {
      customAndFilters: [{ field: "role.permissions", value: role, operator: CondOperator.CONTAINS }],
      join: ["role"],
    };
    return this.optionLoader(inputValue, includeArchived, request);
  }

  public async optionLoader(
    inputValue: string,
    includeArchived?: boolean,
    customRequest?: IRequest
  ): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "username", "firstName", "lastName", "idWinda"],
      [{ field: "username", order: "ASC" }],
      (request) => this.findAll(customRequest ? merge(request, customRequest) : request),
      ["username"],
      {},
      includeArchived
    );
  }

  async updateUserAccess(id: string, user: UpdateUserAccessDto): Promise<UserDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/access",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateUserAccessDto, { ...user, id: id }, { excludeExtraneousValues: true }),
      })
    );
  }

  public async updatePhoto(id: string, photoUrl?: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/photo",
        method: "PATCH",
        auth: true,
        body: { photoUrl },
      })
    );
  }

  getAvailableQualifications(id: string, request: IRequest = {}): Promise<FindManyResult<QualificationDto>> {
    const req = cloneDeep(request);
    req.searchBy = ["id", "name"];
    req.sortBy = "name";
    return this.apiHttpClient
      .request<null, FindManyResult<QualificationDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/available-qualifications",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(QualificationDto, findManyResult.data);
        return findManyResult;
      });
  }
}
