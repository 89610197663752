import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ReferenceDocumentDto } from "./reference-document.dto";
import { CreateReferenceDocumentDto } from "./create-reference-document.dto";
import { UpdateReferenceDocumentDto } from "./update-reference-document.dto";
import { HttpRequest } from "../../common/http/http-request";
import { FindManyResult } from "../../common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { cloneDeep } from "lodash";

export class ReferenceDocumentsResource extends ResourceBaseService<
  ReferenceDocumentDto,
  CreateReferenceDocumentDto,
  UpdateReferenceDocumentDto
> {
  protected EntityDto = ReferenceDocumentDto;
  protected CreateEntityDto = CreateReferenceDocumentDto;
  protected UpdateEntityDto = UpdateReferenceDocumentDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/reference-documents");
  }

  getByProject(id: string, request: IRequest): Promise<FindManyResult<ReferenceDocumentDto>> {
    const req = cloneDeep(request);
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/by-project/" + id,
        method: "GET",
        auth: true,
        query: this.getQueryBuilder(req).query(),
      })
    );
  }
}
