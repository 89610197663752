import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { VehicleDto } from "./vehicle.dto";
import { CreateVehicleDto } from "./create-vehicle.dto";
import { UpdateVehicleDto } from "./update-vehicle.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { HttpRequest } from "@/core/common/http/http-request";

export class VehiclesResource extends ResourceBaseService<VehicleDto, CreateVehicleDto, UpdateVehicleDto> {
  protected EntityDto = VehicleDto;
  protected CreateEntityDto = CreateVehicleDto;
  protected UpdateEntityDto = UpdateVehicleDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicles");
  }

  async optionLoader(inputValue: string, hasArchived?: boolean): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "licensePlate", "code", "model", "brand"],
      [
        { field: "code", order: "ASC" },
        { field: "licensePlate", order: "ASC" },
        { field: "model", order: "ASC" },
        { field: "brand", order: "ASC" },
      ],
      (request) => this.findAll(request),
      ["code", "licensePlate", "model", "brand"],
      {},
      hasArchived
    );
  }

  public async updatePhoto(id: string, photoUrl?: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/photo",
        method: "PATCH",
        auth: true,
        body: { photoUrl },
      })
    );
  }
}
