import { BasePresenter } from "@/core/common/presenters/base.presenter";
import { TaskFilesResource } from "@/core/features/task-files/task-files.resource";
import { IColumn } from "@/interfaces/IColumn";
import { getOverrideColumns, getOverrideFilters } from "@/utils/tableUtils";
import { getTaskFileColumns } from "@/modules/worksheets/tables/task-files/task-file.columns";
import { getTaskFileGridColumns } from "@/modules/worksheets/tables/task-files/task-file-grid.columns";
import { TaskFileDto } from "@/core/features/task-files/task-file.dto";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { IFilterDefinition } from "@/interfaces/IFilterDefinition";
import { getTaskFileFilters } from "@/modules/worksheets/tables/task-files/task-file.filters";
import { getSearchBy } from "@/utils/searchByUtils";
import { UserDto } from "@/core/features/users/user.dto";

export class TaskFilesPresenter extends BasePresenter {
  constructor(protected readonly taskFilesResource: TaskFilesResource) {
    super(taskFilesResource);
  }

  private readonly columns = ["file.mimetype", "file.filename", "worksheet.code", "isPublic", "taskFile.updated_at"];

  getColumns(
    isGrid: boolean,
    overrideColumns?: Partial<IColumn<TaskFileDto>>[],
    columns = this.columns,
    me?: UserDto
  ): IColumn<TaskFileDto>[] {
    return getOverrideColumns(
      isGrid ? getTaskFileGridColumns(me) : getTaskFileColumns(me).filter((column) => columns.includes(column.name)),
      overrideColumns
    );
  }

  getSearchBy(columns: IColumn<TaskFileDto>[]) {
    return getSearchBy(columns);
  }

  getFilters(component: ComponentPublicInstance, overrideFilters?: IFilterDefinition[]): IFilterDefinition[] {
    return getOverrideFilters(getTaskFileFilters(component), overrideFilters);
  }
}
