import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ReviewPeriodEnum } from "@altertec_gparn/lib";
import { QuestionDto } from "@/core/features/questions/question.dto";
import { Type } from "class-transformer";

export class ChecklistDto extends BaseEntityDto {
  reviewPeriod: ReviewPeriodEnum;

  includeIncidentSection: boolean;

  includeObservationSection: boolean;

  isActive: boolean;

  @Type(() => QuestionDto)
  questions: QuestionDto[];
}
