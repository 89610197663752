import { FileDto } from "@/core/features/file/file.dto";
import { formatAppDate } from "@/utils/dates/dateFormats";

const filestackURL = "https://cdn.filestackcontent.com";

export function getFileUrl(handle: string, width?: number, height?: number): string {
  let url = filestackURL;
  if (width && height) url += `/resize=w:${width},h:${height},f:crop,a:center`;
  return `${url}/pjpg=q:90,m:false/${handle}`;
}

export function getThumbnailFromFile(handle: string) {
  return `${filestackURL}/output=format:jpg/${handle}`;
}

export function getFallbackUrl(mimetype: string): string {
  switch (mimetype) {
    case "application/pdf":
      return "/img/pdf-icon.png";
    case "text/excel":
      return "/img/excel-icon.png";
    case "text/plain":
      return "/img/file-icon.png";
    default:
      return "/img/gen-icon.png";
  }
}

export function downloadFiles(fileHandles: string[], filename: string): Promise<void> {
  const bulkName = `GPARN-${formatAppDate(new Date(), false)}-${filename.replace(".", "-")}.zip`;
  return new Promise((resolve, reject) => {
    if (!fileHandles.length) {
      resolve();
      return;
    }
    const url = `https://cdn.filestackcontent.com/zip/content=filename:"GPARN-${formatAppDate(
      new Date(),
      false
    )}-${filename.replace(".", "-")}.zip"/${fileHandles.length > 1 ? `[${fileHandles.join(",")}]` : fileHandles[0]}`;

    const request = new XMLHttpRequest();
    request.open("POST", url, true);
    request.responseType = "arraybuffer";
    request.onload = function () {
      const contentType = this.getResponseHeader("Content-Type");
      if (contentType === "application/x-zip-compressed") {
        const blob = new Blob([this.response], { type: "application/zip" });
        downloadBlobFile(blob, filename);
        resolve();
        return;
      }
      if (!this.response) {
        reject(new Error("Files not found"));
        return;
      }
      const textDecoder = new TextDecoder();
      const jsonResponse = JSON.parse(textDecoder.decode(new Uint8Array(this.response)));
      downloadFile({ url: jsonResponse.url, filename: bulkName } as FileDto)
        .then(() => resolve())
        .catch(() => reject(new Error("Download error")));
    };
    request.onerror = function (error) {
      console.error(error);
      reject(new Error("Network error"));
    };
    request.send();
  });
}

function downloadBlobFile(blob: Blob, filename: string) {
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  const tag = document.createElement("a");
  tag.href = imageUrl;
  tag.download = filename;
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
}

export async function downloadFile(file: FileDto): Promise<void> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open("GET", file.url, true);
    request.responseType = "blob";
    request.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = file.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      resolve();
    };
    request.onerror = function () {
      reject(new Error("Network error"));
    };
    request.send();
  });
}

export function bufferToBlob(data: string, type: string): Blob {
  const byteCharacters = window.atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: type });
}
