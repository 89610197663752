import { ICalendarEventPosition } from "@/interfaces/ICalendarEventPosition";
import { MutationTree } from "vuex";
import { isSameDay } from "date-fns";
import { ICalendarState } from "@/modules/calendar/interfaces/ICalendarState";

export const calendarMutations: MutationTree<ICalendarState> = {
  SET_USER(state: ICalendarState, payload): void {
    state.userId = payload.id;
  },
  CLEAR_DATA(state: ICalendarState): void {
    state.events.length = 0;
  },
  UPDATE_DATE(state: ICalendarState, payload): void {
    state.date = new Date(payload.date);
  },
  ADD_EVENT(state: ICalendarState, payload): void {
    const registeredAlready = state.events.find(
      (storedEvent: ICalendarEventPosition) =>
        storedEvent.id === payload.event.id && isSameDay(storedEvent.date, payload.event.date)
    );
    if (!registeredAlready) {
      state.events.push(payload.event);
    }
  },
  SET_POSITIONS(state: ICalendarState, payload): void {
    payload.events.sort((a: ICalendarEventPosition, b: ICalendarEventPosition) => {
      if (a.type === b.type) {
        return (
          state.events.find((storedEvent) => storedEvent.id === a.id).date.getTime() -
          state.events.find((storedEvent) => storedEvent.id === b.id).date.getTime()
        );
      }
      if (a.type === "time-off") return -1;
      if (a.type === "project") return -1;
      return 1;
    });
    payload.events.forEach((event: ICalendarEventPosition, index: number) => {
      if (event.position === 0) {
        if (index === 0) {
          event.position = 1;
        } else {
          event.position = payload.events[index - 1].position + 1;
        }
      }
      state.events
        .filter((storedEvent: ICalendarEventPosition) => storedEvent.id === event.id)
        .forEach((eventInOtherDate: ICalendarEventPosition) => (eventInOtherDate.position = event.position));
    });
  },
  TOGGLE_WORK_ORDERS(state: ICalendarState, payload): void {
    state.showProjects = payload.value;
  },
  TOGGLE_WORKSHEETS(state: ICalendarState, payload): void {
    state.showWorksheets = payload.value;
  },
  TOGGLE_TIMES_OFF(state: ICalendarState, payload): void {
    state.showTimesOff = payload.value;
  },
  TOGGLE_ON_CALLS(state: ICalendarState, payload): void {
    state.showOnCalls = payload.value;
  },
  RESTORE_POSITIONS(state: ICalendarState): void {
    state.events.forEach((storedEvent: ICalendarEventPosition) => (storedEvent.position = 0));
  },
  WORKSHEETS_HAVE_TIME(state: ICalendarState): void {
    state.worksheetsHaveTime = true;
  },
  RESET(state: ICalendarState): void {
    Object.assign(state, {
      userId: null,
      date: null,
      events: [] as ICalendarEventPosition[],
      showProjects: true,
      showWorksheets: true,
      showTimesOff: true,
      worksheetsHaveTime: false,
    });
  },
};
