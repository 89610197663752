import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { QuestionAnswerType, QuestionDropdownType } from "@altertec_gparn/lib";
import { Type } from "class-transformer";
import { CategoryDto } from "@/core/features/categories/category.dto";
import { DocumentationTypeDto } from "@/core/features/documentation-types/documentation-type.dto";
import { AnswerOptionDto } from "@/core/features/answer-option/answer-option.dto";

export class QuestionDto extends BaseEntityDto {
  title: string;

  subtitle?: string;

  answerType: QuestionAnswerType;

  checklistId?: string;

  dropdownType?: QuestionDropdownType;

  generatesIncident?: boolean;

  @Type(() => CategoryDto)
  equipmentCategory?: CategoryDto;
  equipmentCategoryId?: string;

  @Type(() => DocumentationTypeDto)
  documentationType?: DocumentationTypeDto;
  documentationTypeId?: string;

  @Type(() => AnswerOptionDto)
  answerOptions: AnswerOptionDto[];

  // Subquestion
  @Type(() => AnswerOptionDto)
  relatedAnswer?: AnswerOptionDto;
  relatedAnswerId?: string;

  toString(): string {
    return `${this.title}`;
  }
}
