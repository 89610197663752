import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { AnswerOptionDto } from "@/core/features/answer-option/answer-option.dto";
import { CreateAnswerOptionDto } from "@/core/features/answer-option/create-answer-option.dto";
import { UpdateAnswerOptionDto } from "@/core/features/answer-option/update-answer-option.dto";

export class AnswerOptionsResource extends ResourceBaseService<
  AnswerOptionDto,
  CreateAnswerOptionDto,
  UpdateAnswerOptionDto
> {
  protected EntityDto = AnswerOptionDto;
  protected CreateEntityDto = CreateAnswerOptionDto;
  protected UpdateEntityDto = UpdateAnswerOptionDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/answer-options");
  }

  async createYesNoAnswerOption(questionId: string) {
    const yesAnswerOption = { questionId: questionId, answer: "SI", generatesIncident: false };
    const noAnswerOption = { questionId: questionId, answer: "NO", generatesIncident: false };
    const nAAnswerOption = { questionId: questionId, answer: "N/A", generatesIncident: false };
    await this.createMany([yesAnswerOption, noAnswerOption, nAAnswerOption]);
  }
}
