import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ChecklistDto } from "@/core/features/checklists/checklist.dto";
import { UpdateChecklistDto } from "@/core/features/checklists/update-checklist.dto";

export class ChecklistsResource extends ResourceBaseService<ChecklistDto, null, UpdateChecklistDto> {
  protected EntityDto = ChecklistDto;
  protected UpdateEntityDto = UpdateChecklistDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/checklists");
  }
}
