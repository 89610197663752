import { App } from "vue";
import {
  accountingAccountsResource,
  activityUserReportsResource,
  adjustVacationsDaysResource,
  answerOptionsResource,
  antiFallPpeTypesResource,
  appInfoResource,
  assetsResource,
  authResource,
  brandsResource,
  businessLinesResource,
  calendarsPresenter,
  categoriesResource,
  certificationEconomicAnalysesResource,
  certificationsResource,
  checklistsResource,
  clientGroupsResource,
  clientsResource,
  clientTypesResource,
  collectiveAgreementsHaveDietTypesResource,
  collectiveAgreementsResource,
  commentsResource,
  companiesResource,
  consumablesResource,
  contactsResource,
  dietTypesResource,
  documentationIssuesResource,
  documentationIssueTypesResource,
  documentationTypesResource,
  dossiersPresenter,
  dossiersResource,
  elementsResource,
  elevatorsResource,
  errorNotificationsResource,
  expensesResource,
  expenseTypesResource,
  interventionsResource,
  invoicesResource,
  itemModelsResource,
  itemsResource,
  locationsResource,
  manufacturersResource,
  metricsResource,
  mileagesResource,
  modelsResource,
  offersResource,
  onCallHaveUsersResource,
  onCallsResource,
  onCallTypesResource,
  operativeUnitsHaveOnCallTypesResource,
  operativeUnitsResource,
  ordersResource,
  paymentMethodsResource,
  postsResource,
  projectEconomicAnalysesResource,
  projectPhasesResource,
  projectScheduleUsersResource,
  projectsHaveCertificationsResource,
  projectsHaveContactsResource,
  projectsHaveDietTypesResource,
  projectsHaveFilesResource,
  projectsHaveOrdersResource,
  projectsHaveTaskTypesResource,
  projectsHaveUsersResource,
  projectsHaveVehiclesResource,
  projectsPresenter,
  projectsResource,
  publicViewsResource,
  qualificationsResource,
  questionsResource,
  referenceDocumentsResource,
  reportTimeGroupByResource,
  rolesResource,
  securityManager,
  stockRecordsResource,
  subcategoriesResource,
  subtasksResource,
  supplierResource,
  taskFilesPresenter,
  taskFilesResource,
  tasksResource,
  taskTypesResource,
  timeOffTypesResource,
  timesOffHaveFilesResource,
  timesOffHaveUsersResource,
  timesOffResource,
  usersHaveQualificationsResource,
  usersHaveVacationsResource,
  usersResource,
  vehicleIssuesResource,
  vehicleMaintenancesResource,
  vehiclesHaveDocumentsResource,
  vehiclesResource,
  vehicleTypesHaveChecklistsResource,
  viewsResource,
  warehousesConsumableResource,
  warehousesResource,
  workplacesResource,
  workplaceTypesResource,
  worksheetDocumentsPresenter,
  worksheetDocumentsResource,
  worksheetsHaveUsersResource,
  worksheetsHaveVehiclesResource,
  worksheetsPresenter,
  worksheetsResource,
  zonesResource,
} from "@/core/dependency-injection";

export const diPlugin = (app: App): void => {
  app.config.globalProperties.$appInfo = appInfoResource;
  app.config.globalProperties.$securityManager = securityManager;
  app.config.globalProperties.$authResource = authResource;

  app.config.globalProperties.$answerOptionsResource = answerOptionsResource;
  app.config.globalProperties.$timeOffTypesResource = timeOffTypesResource;
  app.config.globalProperties.$accountingAccountsResource = accountingAccountsResource;
  app.config.globalProperties.$adjustVacationsDaysResource = adjustVacationsDaysResource;
  app.config.globalProperties.$antiFallPpeTypesResource = antiFallPpeTypesResource;
  app.config.globalProperties.$assetsResource = assetsResource;
  app.config.globalProperties.$clientGroupsResource = clientGroupsResource;
  app.config.globalProperties.$clientTypesResource = clientTypesResource;
  app.config.globalProperties.$clientsResource = clientsResource;
  app.config.globalProperties.$subcategoriesResource = subcategoriesResource;
  app.config.globalProperties.$supplierResource = supplierResource;
  app.config.globalProperties.$dietTypesResource = dietTypesResource;
  app.config.globalProperties.$documentationIssueTypesResource = documentationIssueTypesResource;
  app.config.globalProperties.$documentationTypesResource = documentationTypesResource;
  app.config.globalProperties.$elevatorsResource = elevatorsResource;
  app.config.globalProperties.$expenseTypesResource = expenseTypesResource;
  app.config.globalProperties.$interventionsResource = interventionsResource;
  app.config.globalProperties.$collectiveAgreementsResource = collectiveAgreementsResource;
  app.config.globalProperties.$collectiveAgreementsHaveDietTypesResource = collectiveAgreementsHaveDietTypesResource;
  app.config.globalProperties.$companiesResource = companiesResource;
  app.config.globalProperties.$operativeUnitsResource = operativeUnitsResource;
  app.config.globalProperties.$operativeUnitsHaveOnCallTypesResource = operativeUnitsHaveOnCallTypesResource;
  app.config.globalProperties.$paymentMethodsResource = paymentMethodsResource;
  app.config.globalProperties.$usersResource = usersResource;
  app.config.globalProperties.$usersHaveQualificationsResource = usersHaveQualificationsResource;
  app.config.globalProperties.$usersHaveVacationsResource = usersHaveVacationsResource;
  app.config.globalProperties.$workplacesResource = workplacesResource;
  app.config.globalProperties.$workplaceTypesResource = workplaceTypesResource;
  app.config.globalProperties.$manufacturersResource = manufacturersResource;
  app.config.globalProperties.$modelsResource = modelsResource;
  app.config.globalProperties.$taskTypesResource = taskTypesResource;
  app.config.globalProperties.$subtasksResource = subtasksResource;
  app.config.globalProperties.$vehiclesResource = vehiclesResource;
  app.config.globalProperties.$vehicleMaintenancesResource = vehicleMaintenancesResource;
  app.config.globalProperties.$vehiclesHaveDocumentsResource = vehiclesHaveDocumentsResource;
  app.config.globalProperties.$dossiersResource = dossiersResource;
  app.config.globalProperties.$projectsResource = projectsResource;
  app.config.globalProperties.$contactsResource = contactsResource;
  app.config.globalProperties.$projectsHaveDietTypesResource = projectsHaveDietTypesResource;
  app.config.globalProperties.$offersResource = offersResource;
  app.config.globalProperties.$ordersResource = ordersResource;
  app.config.globalProperties.$expensesResource = expensesResource;
  app.config.globalProperties.$elementsResource = elementsResource;
  app.config.globalProperties.$referenceDocumentsResource = referenceDocumentsResource;
  app.config.globalProperties.$worksheetsResource = worksheetsResource;
  app.config.globalProperties.$tasksResource = tasksResource;
  app.config.globalProperties.$taskFilesResource = taskFilesResource;
  app.config.globalProperties.$warehousesResource = warehousesResource;
  app.config.globalProperties.$warehouseConsumablesResource = warehousesConsumableResource;
  app.config.globalProperties.$metricsResource = metricsResource;
  app.config.globalProperties.$consumablesResource = consumablesResource;
  app.config.globalProperties.$stockRecordsResource = stockRecordsResource;
  app.config.globalProperties.$timesOffResource = timesOffResource;
  app.config.globalProperties.$timesOffHaveUsersResource = timesOffHaveUsersResource;
  app.config.globalProperties.$timesOffHaveFilesResource = timesOffHaveFilesResource;
  app.config.globalProperties.$qualificationsResource = qualificationsResource;
  app.config.globalProperties.$documentationIssuesResource = documentationIssuesResource;
  app.config.globalProperties.$projectScheduleUsersResource = projectScheduleUsersResource;
  app.config.globalProperties.$projectsHaveCertificationsResource = projectsHaveCertificationsResource;
  app.config.globalProperties.$projectsHaveFilesResource = projectsHaveFilesResource;
  app.config.globalProperties.$projectsHaveUsersResource = projectsHaveUsersResource;
  app.config.globalProperties.$projectsHaveOrdersResource = projectsHaveOrdersResource;
  app.config.globalProperties.$projectsHaveVehiclesResource = projectsHaveVehiclesResource;
  app.config.globalProperties.$zonesResource = zonesResource;
  app.config.globalProperties.$worksheetsHaveVehiclesResource = worksheetsHaveVehiclesResource;
  app.config.globalProperties.$worksheetsHaveUsersResource = worksheetsHaveUsersResource;
  app.config.globalProperties.$worksheetDocumentsResource = worksheetDocumentsResource;
  app.config.globalProperties.$certificationsResource = certificationsResource;
  app.config.globalProperties.$certificationEconomicAnalysesResource = certificationEconomicAnalysesResource;
  app.config.globalProperties.$checklistsResource = checklistsResource;
  app.config.globalProperties.$activityUserReportsResource = activityUserReportsResource;
  app.config.globalProperties.$reportTimeGroupByResource = reportTimeGroupByResource;
  app.config.globalProperties.$commentsResource = commentsResource;
  app.config.globalProperties.$rolesResource = rolesResource;
  app.config.globalProperties.$locationsResource = locationsResource;
  app.config.globalProperties.$invoicesResource = invoicesResource;
  app.config.globalProperties.$itemModelsResource = itemModelsResource;
  app.config.globalProperties.$itemsResource = itemsResource;
  app.config.globalProperties.$mileagesResource = mileagesResource;
  app.config.globalProperties.$projectsHaveContactsResource = projectsHaveContactsResource;
  app.config.globalProperties.$projectsHaveTaskTypesResource = projectsHaveTaskTypesResource;
  app.config.globalProperties.$projectEconomicAnalysesResource = projectEconomicAnalysesResource;
  app.config.globalProperties.$businessLinesResource = businessLinesResource;
  app.config.globalProperties.$brandsResource = brandsResource;
  app.config.globalProperties.$categoriesResource = categoriesResource;
  app.config.globalProperties.$viewsResource = viewsResource;
  app.config.globalProperties.$projectPhasesResource = projectPhasesResource;
  app.config.globalProperties.$publicViewsResource = publicViewsResource;
  app.config.globalProperties.$errorNotificationsResource = errorNotificationsResource;
  app.config.globalProperties.$postsResource = postsResource;
  app.config.globalProperties.$vehicleTypesHaveChecklistsResource = vehicleTypesHaveChecklistsResource;
  app.config.globalProperties.$questionsResource = questionsResource;
  app.config.globalProperties.$onCallsResource = onCallsResource;
  app.config.globalProperties.$onCallTypesResource = onCallTypesResource;
  app.config.globalProperties.$onCallHaveUsersResource = onCallHaveUsersResource;
  app.config.globalProperties.$vehicleIssuesResource = vehicleIssuesResource;

  // Presenters
  app.config.globalProperties.$dossiersPresenter = dossiersPresenter;
  app.config.globalProperties.$projectsPresenter = projectsPresenter;
  app.config.globalProperties.$worksheetsPresenter = worksheetsPresenter;
  app.config.globalProperties.$taskFilesPresenter = taskFilesPresenter;
  app.config.globalProperties.$worksheetDocumentsPresenter = worksheetDocumentsPresenter;
  app.config.globalProperties.$calendarsPresenter = calendarsPresenter;
};
