import { CurrencyEnum, LangEnum } from "@altertec_gparn/lib";

export class NumberFormatterService {
  formatNumber(num: number, lang: LangEnum): string {
    return num.toLocaleString(lang);
  }

  formatPercent(num: number, lang: LangEnum): string {
    return (num / 100.0).toLocaleString(lang, {
      style: "percent",
      maximumFractionDigits: 2,
    });
  }

  formatCurrency(num: number, lang: LangEnum, currency: CurrencyEnum): string {
    return num.toLocaleString(lang, { style: "currency", currency: currency });
  }

  formatDistance(num: number): string {
    return `${num.toLocaleString(LangEnum.ES, { maximumFractionDigits: 2 })} Km`;
  }
}
