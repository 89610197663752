import { Type } from "class-transformer";
import { DocumentationTypeDto } from "../documentation-types/documentation-type.dto";
import { FileDto } from "../file/file.dto";
import { ModelDto } from "../models/model.dto";
import { InterventionDto } from "../interventions/intervention.dto";
import { ElementDto } from "../element/element.dto";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ClientGroupDto } from "@/core/features/client-groups/client-group.dto";

export class ReferenceDocumentDto extends BaseEntityDto {
  @Type(() => DocumentationTypeDto)
  documentationType: DocumentationTypeDto;
  documentationTypeId: string;

  @Type(() => FileDto)
  file: FileDto;

  @Type(() => ModelDto)
  models: ModelDto[];
  modelIds: string[];

  @Type(() => ElementDto)
  elements: ElementDto[];
  elementIds: string[];

  @Type(() => InterventionDto)
  interventions: InterventionDto[];
  interventionIds: string[];

  @Type(() => ClientGroupDto)
  clientGroups: ClientGroupDto[];
  clientGroupIds: string[];
}
