import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WarehouseConsumableDto } from "@/core/features/warehouse-consumable/warehouse-consumable.dto";
import { CreateWarehouseConsumableDto } from "@/core/features/warehouse-consumable/create-warehouse-consumable.dto";
import { UpdateWarehouseConsumableDto } from "@/core/features/warehouse-consumable/update-warehouse-consumable.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";

export class WarehousesConsumableResource extends ResourceBaseService<
  WarehouseConsumableDto,
  CreateWarehouseConsumableDto,
  UpdateWarehouseConsumableDto
> {
  protected EntityDto = WarehouseConsumableDto;
  protected CreateEntityDto = CreateWarehouseConsumableDto;
  protected UpdateEntityDto = UpdateWarehouseConsumableDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/warehouse-consumables");
  }

  async getStockByWarehouseConsumable(warehouseId: string, consumableId: string): Promise<WarehouseConsumableDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/warehouse/" + warehouseId + "/consumable/" + consumableId,
          method: "GET",
          auth: true,
        })
      )
      .then((result) => plainToInstance(WarehouseConsumableDto, result));
  }

  async getNotEnoughStockInWarehouse(warehouseId: string): Promise<number> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/" + warehouseId + "/min-stock",
          method: "GET",
          auth: true,
        })
      )
      .then((result) => Number(result));
  }
}
