import { CondOperator, QuerySort, RequestQueryBuilder, SCondition } from "@nestjsx/crud-request";
import { IRequest } from "@/interfaces/IRequest";
import { IRequestFilter } from "@/interfaces/IRequestFilter";
import { IRangeFilter } from "@/interfaces/IRangeFilter";
import { IFilterModel } from "@/interfaces/IFilterModel";
import { cloneDeep, get, isArray, uniq } from "lodash";
import { ONLY_NOT_NULL, ONLY_NULL } from "@/constants/Filters";
import { DateFilterEnum, getDateFilterValue } from "@/utils/dates/date-filter.enum";

export class QueryRequestHelper {
  protected request: RequestQueryBuilder = null;

  constructor(inputRequest: IRequest) {
    this.request = RequestQueryBuilder.create();
    const {
      filters,
      search,
      sortBy,
      sort,
      descending,
      page,
      rowsPerPage,
      searchBy,
      join,
      limit,
      offset,
      orFilters,
      customAndFilters,
    } = cloneDeep(inputRequest) || {};
    if (limit) this.request.setLimit(limit);
    if (offset) this.request.setOffset(offset);

    if ((join && join.length > 0) || get(filters, "requireJoin")) this.setJoins(join, get(filters, "requireJoin"));
    if (rowsPerPage) this.request.setLimit(rowsPerPage);
    if (sortBy || sort) this.request.sortBy(this.getSortArray(sortBy, sort as QuerySort[], descending));
    this.request.setPage(page);
    this.request.search({ $and: this.getFilterQuery(filters, orFilters, search, searchBy, customAndFilters) });
  }

  getRequest(): RequestQueryBuilder {
    return this.request;
  }

  getFilterQuery(
    filters: IFilterModel,
    orFilters: IRequestFilter[],
    search: string,
    searchBy: string[],
    customAndFilters: IRequestFilter[]
  ): SCondition[] {
    const filterConditions: SCondition[] = [];
    if (filters && Object.keys(filters).length > 0) {
      const rangeDateConditions = this.getRangeDateConditions(filters);
      this.cleanParams(filters);
      if (rangeDateConditions) {
        filterConditions.push(rangeDateConditions);
      }
      filterConditions.push({ $and: this.mapFiltersToConditions(filters) });
    }
    if (orFilters && Object.keys(orFilters).length > 0)
      filterConditions.push({ $or: this.mapRequestFiltersToConditions(orFilters) });
    if (search && searchBy.length > 0)
      filterConditions.push({ $and: [{ $or: this.mapToSearchConditions(searchBy, search) }] });
    if (customAndFilters) {
      filterConditions.push({ $and: [{ $and: this.mapRequestFiltersToConditions(customAndFilters) }] });
    }
    return filterConditions;
  }

  setJoins(joins: string[], dynamicJoins: string | string[]) {
    const joinList = dynamicJoins
      ? uniq([...joins, ...(isArray(dynamicJoins) ? dynamicJoins : [dynamicJoins])])
      : joins;
    this.request.setJoin(joinList.map((field: string) => ({ field })));
  }

  convertDateRangeToRangeFilter(filterModel: IFilterModel) {
    const filters: IRangeFilter = Object.assign(
      {},
      {
        fromDate: getDateFilterValue(filterModel.from as DateFilterEnum),
        untilDate: getDateFilterValue(filterModel.to as DateFilterEnum),
        startDateField: filterModel.startDateField,
        endDateField: filterModel.endDateField,
      }
    ) as IRangeFilter;
    delete filterModel.to;
    delete filterModel.from;
    return this.getRangeDateConditions(filters);
  }

  getRangeDateConditions(filters: IRangeFilter | IFilterModel): SCondition {
    if ((filters as IFilterModel).to && (filters as IFilterModel).from) {
      return this.convertDateRangeToRangeFilter(filters as IFilterModel);
    }

    return Object.keys(filters).includes("fromDate") || Object.keys(filters).includes("untilDate")
      ? { $and: this.mapFromUntil(filters as IRangeFilter) }
      : null;
  }

  cleanParams(filters: { [key: string]: unknown }): void {
    delete filters["fromDate"];
    delete filters["untilDate"];
    delete filters["startDateField"];
    delete filters["endDateField"];
    delete filters["requireJoin"];
  }

  getSortArray(sortBy: string, sort: QuerySort[], descending: boolean): QuerySort[] {
    const sortRules = sort ?? [];
    if (sortBy) {
      const rule = sortRules.find((rule) => rule.field === sortBy);
      if (!rule) {
        sortRules.push({ field: sortBy, order: descending ? "DESC" : "ASC" });
      } else {
        rule.order = descending ? "DESC" : "ASC";
      }
    }
    return sortRules;
  }

  mapRequestFiltersToConditions(orFilters: IRequestFilter[]): SCondition[] {
    return orFilters.map((filter) => {
      return { [filter.field]: { [filter.operator]: filter.value } };
    });
  }

  mapFiltersToConditions(filters: IFilterModel): SCondition[] {
    const keys = Object.keys(filters);

    return keys.map((key) => {
      const value = filters[key];
      if (value === ONLY_NULL) {
        return { [key]: { [CondOperator.IS_NULL]: ONLY_NULL } };
      } else if (value === ONLY_NOT_NULL) {
        return { [key]: { [CondOperator.NOT_NULL]: ONLY_NOT_NULL } };
      } else if (isArray(value) && value.length > 1) {
        const conditions = [];
        for (const item of value) {
          conditions.push({ [key]: { [CondOperator.EQUALS]: item } });
        }
        return { $or: conditions };
      }
      return { [key]: { [CondOperator.EQUALS]: value } };
    });
  }

  mapFromUntil(rangeFilters: IRangeFilter): SCondition[] {
    const { fromDate, untilDate, startDateField, endDateField } = rangeFilters;

    if (!endDateField || endDateField === startDateField) {
      const startField = startDateField ?? "date";
      return [
        {
          [startField]: {
            [CondOperator.GREATER_THAN_EQUALS]: getDateFilterValue(fromDate as DateFilterEnum),
            [CondOperator.LOWER_THAN_EQUALS]: getDateFilterValue(untilDate as DateFilterEnum),
          },
        },
      ];
    }
    return [
      {
        [startDateField]: {
          [CondOperator.LOWER_THAN_EQUALS]: getDateFilterValue(untilDate as DateFilterEnum),
        },
        [endDateField]: {
          [CondOperator.GREATER_THAN_EQUALS]: getDateFilterValue(fromDate as DateFilterEnum),
        },
      },
    ];
  }

  mapToSearchConditions(searchBy: string[], search: string): SCondition[] {
    return searchBy.map((searchField) => {
      const operator = searchField == "id" ? CondOperator.EQUALS : CondOperator.CONTAINS;
      return { [searchField]: { [operator]: search } };
    });
  }
}
